import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public baseUrl = 'https://carwashcloudapi.msaleservices.com';

  constructor(
    private http: HttpClient
  ) { }

  public getAllEvents(paginationUrl){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer  ${access_token}`);
    const userCompany = localStorage.getItem('userCompany');
    headers = headers.set('x-company-id', userCompany);
    return this.http.get(this.baseUrl+paginationUrl, {headers});
  }

  public getSingleEvent(id){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${access_token}`);
    return this.http.get(this.baseUrl+`/api/v1/transactions/${id}`, {headers});
  }
}
