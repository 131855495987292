import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, retry, catchError, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class HttpinterceptorService implements HttpInterceptor {
  token: string;
  omitCalls = ['login']
  skipInterceptor = false;


  constructor(
    private router: Router,
    public userService: UserService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(retry(2)).pipe(catchError(error => {
      if (error.status === 401 || error.status === 422) {
        const refreshAttempt = localStorage.getItem('refreshAttempted')
        if (refreshAttempt === '1') {
          console.log('Already Attempted a Refresh');
        } else {
          localStorage.setItem('refreshAttempted', '1')
          this.userService.refreshToken().subscribe(
            (success: any) => {
              localStorage.setItem('refreshAttempted', '0')
              localStorage.setItem('access_token', success.access_token);
              window.location.reload();
            }, (refreshError: any) => {
              this.router.navigate(['/login'])
              localStorage.setItem('refreshAttempted', '0')
            }
          )
        }
      }
      return throwError(error);
    }))
  }
}
