import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ValuecardService {

  public baseUrl = 'https://carwashcloudapi.msaleservices.com';
  constructor(
    private http: HttpClient
  ) { }

  public getAllValueCard(paginationUrl){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${access_token}`);
    const userCompany = localStorage.getItem('userCompany');
    headers = headers.set('x-company-id', userCompany);
    return this.http.get(this.baseUrl+paginationUrl, {headers});
  }

  public getValueCard(id){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${access_token}`);
    return this.http.get(this.baseUrl+`/api/v1/valuecard/${id}`, {headers});
  }

  public getLastValuecard(page, perPage){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${access_token}`);
    const userCompany = localStorage.getItem('userCompany');
    headers = headers.set('x-company-id', userCompany);
    return this.http.get(this.baseUrl+`/api/v1/valuecard?page=${page}&per_page=${perPage}`, {headers});
  }

  public postValueCard(data){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${access_token}`);
    return this.http.post(this.baseUrl+'/api/v1/valuecard', data, {headers});
  }

  public putValueCard(id, data){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${access_token}`);
    return this.http.put(this.baseUrl+`/api/v1/valuecard/${id}`, data, {headers});
  }

  public deleteValueCard(id){
    let headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${access_token}`);
    return this.http.delete(this.baseUrl+`/api/v1/valuecard/${id}`, {headers});
  }
}
