import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUrl = 'https://carwashcloudapi.msaleservices.com';
  constructor(
    private http: HttpClient,
    private router: Router) { }

  public login(loginDict) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + '/auth/login', loginDict);
  }

  public logout() {
    localStorage.setItem('access_token', '');
    this.router.navigate(['/login']);
  }

  public refreshToken(){
    let headers = new HttpHeaders();
    const refreshToken = localStorage.getItem('refresh_token');
    console.log('Refresh Token: ', refreshToken);
    headers = headers.set('Authorization', `Bearer ${refreshToken}`);
    return this.http.post(this.baseUrl + '/auth/refresh', {} ,{headers});
  }

  public createUser(userForm) {
    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.post(this.baseUrl+'/api/v1/users', userForm, { headers });
  }

  public getUser(userId){
    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.get(this.baseUrl + '/api/v1/users/' + userId, {
      headers
    });
  }

  public getAllUsers(paginationUrl) {
    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);
    const userCompany = localStorage.getItem('userCompany');
    headers = headers.set('x-company-id', userCompany);

    return this.http.get(this.baseUrl+paginationUrl, { headers });
  }

  public updateUser(id: any, postData) {

    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.put(this.baseUrl + `/${id}`, postData, { headers });
  }

  public deleteUser(id: any) {
    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.delete(this.baseUrl + `/${id}`, { headers });
  }

  public isAuthenticated(): boolean {
    const localToken = localStorage.getItem('access_token');
    try {
      return localToken.length > 2;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

}
