import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdamService {

  public baseUrl = 'https://carwashcloudapi.msaleservices.com/api/v1/adam';

  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  public createAdam(adamForm) {

    console.log(adamForm);

    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.post(this.baseUrl, adamForm, { headers });
  }

  public getAdams() {
    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.get(this.baseUrl, { headers });
  }

  public getAdam(id: any){
    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);


    return this.http.get(this.baseUrl + `/${id}`, { headers });
  }

  public updateAdam(id: any, postData) {

    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.put(this.baseUrl + `/${id}`, postData, { headers });
  }

  public deleteAdam(id: any) {
    let headers = new HttpHeaders();
    const auth_token = localStorage.getItem('access_token');
    headers = headers.set('Authorization', `Bearer ${auth_token}`);

    return this.http.delete(this.baseUrl + `/${id}`, { headers });
  }


}
