import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public userService: UserService,
    private router: Router,
  ) { }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
    if (this.userService.isAuthenticated()){
      return true;
    }
    console.log("Not Authenticated", this.userService.isAuthenticated());

    this.router.navigate(['/login']);
    return false;
  }
}
