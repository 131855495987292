import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'pos',
    loadChildren: () => import('./pages/points-of-sale/points-of-sale.module').then( m => m.PointsOfSalePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'company-list',
    loadChildren: () => import('./pages/company-list/company-list.module').then( m => m.CompanyListPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/user/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'user/create',
    loadChildren: () => import('./pages/user/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'user/list',
    loadChildren: () => import('./pages/user/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'user/details/:userId',
    loadChildren: () => import('./pages/user/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'valuecard/list',
    loadChildren: () => import('./pages/valuecard/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'valuecard/create/:companyId',
    loadChildren: () => import('./pages/valuecard/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'valuecard/details/:cardId',
    loadChildren: () => import('./pages/valuecard/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'reporting/type-select',
    loadChildren: () => import('./pages/reporting/type-select/type-select.module').then( m => m.TypeSelectPageModule)
  },
  {
    path: 'reporting/invoices',
    loadChildren: () => import('./pages/reporting/invoices/invoices.module').then( m => m.InvoicesPageModule)
  },
  {
    path: 'reporting/sales',
    loadChildren: () => import('./pages/reporting/sales/sales.module').then( m => m.SalesPageModule)
  },
  {
    path: 'reporting/events',
    loadChildren: () => import('./pages/reporting/events/events.module').then( m => m.EventsPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
