import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdamService } from 'src/app/services/adam.service';

@Component({
  selector: 'app-adam-assign',
  templateUrl: './adam-assign.component.html',
  styleUrls: ['./adam-assign.component.scss'],
})
export class AdamAssignComponent implements OnInit {

  public allAdams: any;
  public adam: any;
  public selectedAdam: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public adamService: AdamService,
  ) { }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params.posId;
/*
    this.adamService.getSingleAdam(id).subscribe(
      (success: any) => {
        this.adam = success;
        console.log("Success in getting da ADAM", success);
      },
      (error: any) => {
        console.log("Error on grabbing a single ADAM", error);
      }
    );
    */

    this.populateAdams();
  }

  public populateAdams() {
    this.allAdams = [];
    this.adamService.getAdams().subscribe(
      (success: any) => {
        console.log(`getallAdams Success!: ${success.length}`, success);
        this.allAdams = success;
      },
      (error: any) => {
        console.log(`getallAdams Failed!: `, error);
      }
    )
  }


}
