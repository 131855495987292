import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { ValuecardService } from 'src/app/services/valuecard.service';

@Component({
  selector: 'app-bulk-download',
  templateUrl: './bulk-download.component.html',
  styleUrls: ['./bulk-download.component.scss'],
})
export class BulkDownloadComponent implements OnInit {

  @Input() valueCards: any;
  public processedCards = 0;
  public fromDate: any;
  public toDate: any;
  public processing = false;
  public csvItems = [];
  public csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    headers: ["washCompanyName", "washCustomerName", "washName", "washOriginalPrice", "valuecardDiscountPercentage", "valuecardCarLicense", "washTimestamp", "washTotalPrice"],
    useBom: false,
    removeNewLines: true,
    keys: ["washCompanyName", "washCustomerName", "washName", "washOriginalPrice", "valuecardDiscount", "valuecardCarLicense", "washTimestamp", "washTotalPrice"],
    title: "",
    showTitle: false,
    filename: ""
  }



  constructor(
    public eventService: EventsService,
    public valuecardService: ValuecardService,
  ) { }

  ngOnInit() {
    console.log('New ValueCards: ', this.valueCards)
    const oldFromDateString = localStorage.getItem('oldFromDateString');
    const oldToDateString = localStorage.getItem('oldToDateString');
    if (oldFromDateString){
      this.fromDate = oldFromDateString;
    } else {
      var tempFromDate = new Date()
      this.fromDate = new Date(tempFromDate.getFullYear(), tempFromDate.getMonth()-1, 1).toISOString()
    }
    if (oldToDateString) {
      this.toDate = oldToDateString;
    } else {
      var tempToDate = new Date()
      this.toDate = new Date(tempToDate.getFullYear(), tempFromDate.getMonth()-1, 31).toISOString()
    }

  }

  public populateData(fromDate, toDate){
    localStorage.setItem('oldFromDateString', fromDate);
    localStorage.setItem('oldToDateString', toDate);
    this.processing = true;
    this.valueCards.forEach( (valuecard) => {
      console.log('Grabbing info for valuecard: ', valuecard);
      this.eventService.getAllEvents(`/api/v1/events?per_page=50000000&from_date=${fromDate.split('+')[0]}&to_date=${toDate.split('+')[0]}&invoice=true&valuecard_id=${valuecard.id}`).subscribe(
        (success: any) => {
          console.log('Got events for Valuecard: ', success)
          this.processCards(success.results);
        }, (error: any) => {
          console.log('Error grabbing events for Valuecard: ', error)
        }
      )
    })
  }

  public processCards(events){
    events.forEach( (washEvent: any) => {
      let csvItem: any = {}
      let totalPrice = 0;
      if (washEvent.event_json.wash){
        totalPrice = totalPrice + (washEvent.event_json.wash.wash_price * (washEvent.event_json.valuecard.discount/100));
        csvItem = {
          washName: washEvent.event_json.wash.wash_name,
          valuecardDiscount: washEvent.event_json.valuecard.discount,
          valuecardCarLicense: washEvent.event_json.valuecard.cardholder_car_license,
          washTimestamp: washEvent.utc_timestamp,
          washCompanyName: washEvent.event_json.valuecard.cardholder_companyname,
          washCustomerName: washEvent.event_json.valuecard.cardholder_name,
          washTotalPrice: washEvent.event_json.wash.wash_price - (washEvent.event_json.wash.wash_price * (washEvent.event_json.valuecard.discount/100)),
        }
        if (washEvent.event_json.wash.wash_price === 0){
          csvItem.washOriginalPrice = 0
        } else{
          csvItem.washOriginalPrice = washEvent.event_json.wash.wash_price
        }
        console.log('New CSV Item: ', csvItem);

      } else{
        totalPrice = totalPrice + washEvent.event_json.totalWashed
        csvItem = {
          washName: "Fakturavask",
          valuecardDiscount: washEvent.event_json.valuecard.discount,
          valuecardCarLicense: washEvent.event_json.valuecard.cardholder_car_license,
          washTimestamp: washEvent.utc_timestamp,
          washOriginalPrice: washEvent.event_json.totalWashed,
          washCompanyName: washEvent.event_json.valuecard.cardholder_companyname,
          washCustomerName: washEvent.event_json.valuecard.cardholder_name,
          washTotalPrice: washEvent.event_json.totalWashed
        }
      }
      this.csvItems.push(csvItem)
    })
    this.processedCards = this.processedCards + 1;
    const updateDict = {
      previous_invoice_date_start: this.fromDate,
      previous_invoice_date_end: this.toDate
    }
    this.valuecardService.putValueCard(events[0].event_json.valuecard.id, updateDict).subscribe(
      (success: any) => {
        console.log('Valuecard Edited: ', success);
      }, (error: any) => {
        console.log('Valuecard edit failed: ', error);
      }
    )
  }
}
