import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AdamAssignComponent } from './modals/adam-assign/adam-assign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpinterceptorService } from './services/httpinterceptor.service';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJs from 'plotly.js-dist-min';
import { BulkDownloadComponent } from './modals/bulk-download/bulk-download.component';

PlotlyModule.plotlyjs = PlotlyJs;

//test
@NgModule({
    declarations: [AppComponent, AdamAssignComponent, BulkDownloadComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        PlotlyModule,
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: HttpinterceptorService, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule { }
